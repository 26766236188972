import { render, staticRenderFns } from "./AboutVrx2.vue?vue&type=template&id=4e649500&scoped=true&"
import script from "./AboutVrx2.vue?vue&type=script&lang=js&"
export * from "./AboutVrx2.vue?vue&type=script&lang=js&"
import style0 from "./AboutVrx2.vue?vue&type=style&index=0&id=4e649500&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e649500",
  null
  
)

export default component.exports